/* Overriding Prosemirror styles + Tailwind 
   defaults that could interfere 
*/

.editor ul,
.description ul {
  padding-left: 1rem;
  list-style-type: disc;
}

.editor ol,
.description ol {
  padding-left: 1.2rem;
  list-style-type: decimal;
}

/* Force empty p tags within description to render full line break */
.description p::after {
  content: '';
  display: inline-block;
  width: 0px;
}

.editor p a,
.description p a {
  text-decoration: none;
  word-break: break-all;
}

:global(.light) .editor p a,
:global(.light) .description p a {
  @apply text-blue-500;
}

:global(.dark) .editor p a,
:global(.dark) .description p a {
  @apply text-blue-300;
}

.description {
  word-break: break-word;
}

.description p a:hover {
  text-decoration: underline;
}

.editor p,
.description p {
  font-weight: 400;
}
