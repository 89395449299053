.datepicker {
  display: flex;
}

.datepicker :global(.react-datepicker__header) {
  border-bottom: none;
  @apply rounded-tl-xl;
  @apply rounded-tr-xl;
  background-color: transparent;
}

.datepicker :global(.react-datepicker__triangle) {
  display: none;
}

.datepicker :global(.react-datepicker) {
  border: none;
  font-family: inherit;
  @apply rounded-xl;
  @apply shadow-popover;
}

.datepicker :global(.react-datepicker__month) {
  margin-left: 8px;
  margin-right: 8px;
  margin-top: 0;
}

.datepicker :global(.react-datepicker__input-container) {
  display: flex;
}

.datepicker :global(.react-datepicker__day--outside-month) {
  visibility: hidden;
}

.datepicker :global(.react-datepicker__day-name) {
  color: var(--color-text-secondary);
  opacity: 0.6;
  @apply font-semibold;
  @apply text-s;
  @apply py-1;
}

.datepicker :global(.react-datepicker-popper) {
  z-index: 900;
}

.datepicker :global(.react-datepicker__day) {
  border-radius: 50%;
  transition-duration: 150ms;
  transition-property: color, background-color;
}

.datepicker :global(.react-datepicker__day--keyboard-selected),
.datepicker :global(.react-datepicker__day:hover) {
  @apply bg-gray-500;
  color: white;
  border-radius: 50%;
}

.datepicker :global(.react-datepicker__day--selected),
.datepicker :global(.react-datepicker__day--selected:hover) {
  @apply bg-gray-400;
  color: white;
  border-radius: 50%;
}

.datepicker :global .dark .react-datepicker__day--keyboard-selected:hover,
.datepicker :global .dark .react-datepicker__day--selected:hover {
  @apply bg-gray-500;
}

.datepicker
  :global(.react-datepicker__day--today:not(.react-datepicker__day--selected)) {
  @apply text-gray-400;
}

/* ------- colour based on user selection ------- */
/* --- GREEN --- */
.event-color--green :global(.react-datepicker__day--selected) {
  @apply bg-green-400;
}

.event-color--green :global(.react-datepicker__day--selected:hover) {
  @apply bg-green-500;
}

.event-color--green
  :global(.react-datepicker__day--today:not(.react-datepicker__day--selected)) {
  @apply text-green-400;
}

/* --- YELLOW --- */
.event-color--yellow :global(.react-datepicker__day--selected) {
  background-color: #facc15;
  @apply bg-yellow-400;
}

.event-color--yellow :global(.react-datepicker__day--selected:hover) {
  @apply bg-yellow-500;
}

.event-color--yellow
  :global(.react-datepicker__day--today:not(.react-datepicker__day--selected)) {
  @apply text-yellow-400;
}

/* --- ORANGE --- */
.event-color--orange :global(.react-datepicker__day--selected) {
  @apply bg-orange-400;
}

.event-color--orange :global(.react-datepicker__day--selected:hover) {
  @apply bg-orange-500;
}

.event-color--orange
  :global(.react-datepicker__day--today:not(.react-datepicker__day--selected)) {
  @apply text-orange-400;
}

/* --- RED --- */
/* red-400 */
.event-color--red :global(.react-datepicker__day--selected) {
  @apply bg-red-400;
}

/* red-500 */
.event-color--red :global(.react-datepicker__day--selected:hover) {
  @apply bg-red-500;
}

/* red-400 */
.event-color--red
  :global(.react-datepicker__day--today:not(.react-datepicker__day--selected)) {
  @apply text-red-400;
}

/* --- BLUE --- */

.event-color--blue :global(.react-datepicker__day--selected) {
  @apply bg-blue-400;
}

.event-color--blue :global(.react-datepicker__day--selected:hover) {
  @apply bg-blue-500;
}

.event-color--blue
  :global(.react-datepicker__day--today:not(.react-datepicker__day--selected)) {
  @apply text-blue-400;
}

/* --- PURPLE --- */
.event-color--purple :global(.react-datepicker__day--selected) {
  @apply bg-purple-400;
}

.event-color--purple :global(.react-datepicker__day--selected:hover) {
  @apply bg-purple-500;
}

.event-color--purple
  :global(.react-datepicker__day--today:not(.react-datepicker__day--selected)) {
  @apply text-purple-400;
}

/* --- PINK --- */
.event-color--pink :global(.react-datepicker__day--selected) {
  @apply bg-pink-400;
}

.event-color--pink :global(.react-datepicker__day--selected:hover) {
  @apply bg-pink-500;
}

.event-color--pink
  :global(.react-datepicker__day--today:not(.react-datepicker__day--selected)) {
  @apply text-pink-400;
}

/* --- GRAY --- */
.event-color--gray :global(.react-datepicker__day--selected) {
  @apply bg-gray-400;
}

.event-color--gray :global(.react-datepicker__day--selected:hover) {
  @apply bg-gray-500;
}

.event-color--gray
  :global(.react-datepicker__day--today:not(.react-datepicker__day--selected)) {
  @apply text-gray-400;
}
